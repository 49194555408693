/**
 * Runs critical JS inside the HEAD tag
 * @author Josh Smith <josh@batch.nz>
 */
import { applyTheme } from "./themes";

window.addEventListener("DOMContentLoaded", (event) => {
    // Apply the users current theme to the website
    applyTheme();
});
